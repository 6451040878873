<template>
  <v-dialog v-model="showing" max-width="85%">
    <v-card>
      <v-card-title class="headline" primary-title>
        Redigér rapport
      </v-card-title>

      <v-card-text v-if="value && value.id">
        <simple-rich-text-input v-model="summary"></simple-rich-text-input>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" :loading="loading" @click="save()">
          Gem
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SimpleRichTextInput from "../../inputs/SimpleRichTextInput";
import { mapActions } from "vuex";

export default {
  components: { SimpleRichTextInput },
  props: {
    value: {
      required: true,
      default: false
    }
  },
  data: () => ({
    loading: false,
    summary: ""
  }),
  computed: {
    showing: {
      get() {
        return this.value !== null;
      },
      set() {
        this.$emit("input", null);
      }
    }
  },
  watch: {
    value() {
      let clone = this.cloneDeep(this.value);

      if (clone) {
        this.summary = clone.summary;
      }
    }
  },
  methods: {
    ...mapActions("moderator", ["updateManagedReport"]),

    save() {
      this.loading = true;
      const params = {
        summary: this.summary,
        id: this.value.id
      };
      this.updateManagedReport(params).then(
        response => {
          this.setSnackSuccess("Gemt");
          this.loading = false;
          this.$emit("updated", response);
        },
        () => {
          this.setSnackError("Fejl");
          this.loading = false;
        }
      );
    }
  }
};
</script>
