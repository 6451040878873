var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-layout',{staticClass:"mb-3",attrs:{"row":"","wrap":"","align-center":""}},[_c('v-flex',{staticClass:"text-right"},[_c('v-btn',{staticClass:"hidden-xs-only mr-0",attrs:{"color":"primary","to":{ name: 'create_managed_report' }}},[_c('v-icon',{attrs:{"color":"white","left":""}},[_vm._v("fal fa-plus")]),_vm._v(" Opret rapport ")],1),_c('v-btn',{staticClass:"hidden-sm-and-up",attrs:{"color":"primary","fixed":"","bottom":"","right":"","fab":"","to":{ name: 'create_managed_report' }}},[_c('v-icon',[_vm._v("fal fa-plus")])],1)],1)],1),_c('v-card',[_c('v-card-title',[_c('div',[_c('div',{staticClass:"headline"},[_vm._v("Managed afrapportering")]),_c('span',{staticClass:"subtitle-1 light-grey"})])]),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.reports,"hide-default-footer":"","items-per-page":10000},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.id},[_c('td',[_vm._v(_vm._s(item.business.display_name))]),_c('td',[_vm._v(_vm._s(_vm._f("date_format")(item.date,"MMMM YYYY")))]),_c('td',{staticClass:"text-right"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","outlined":"","icon":""},on:{"click":function($event){_vm.selectedReport = item}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fal fa-pen")])],1)]}}],null,true)},[_c('span',[_vm._v("Redigér")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","outlined":"","icon":"","href":item.url,"target":"_blank"}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fal fa-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Se rapport")])])],1)])}),0)]}}])},[_c('v-progress-linear',{attrs:{"slot":"progress","color":"primary","indeterminate":""},slot:"progress"}),_c('div',{staticClass:"text-center",attrs:{"slot":"no-data"},slot:"no-data"},[_vm._v(" Ingen resultater :( ")])],1)],1)],1),_c('edit-managed-report-dialog',{on:{"updated":function($event){_vm.getReports();
      _vm.selectedReport = null;}},model:{value:(_vm.selectedReport),callback:function ($$v) {_vm.selectedReport=$$v},expression:"selectedReport"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }