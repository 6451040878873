<template>
  <div>
    <v-layout row wrap align-center class="mb-3">
      <v-flex class="text-right">
        <v-btn
          color="primary"
          class="hidden-xs-only mr-0"
          :to="{ name: 'create_managed_report' }"
        >
          <v-icon color="white" left>fal fa-plus</v-icon> Opret rapport
        </v-btn>
        <v-btn
          color="primary"
          class="hidden-sm-and-up"
          fixed
          bottom
          right
          fab
          :to="{ name: 'create_managed_report' }"
        >
          <v-icon>fal fa-plus</v-icon>
        </v-btn>
      </v-flex>
    </v-layout>

    <v-card>
      <v-card-title>
        <div>
          <div class="headline">Managed afrapportering</div>
          <span class="subtitle-1 light-grey"></span>
        </div>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="reports"
          hide-default-footer
          :items-per-page="10000"
        >
          <v-progress-linear
            slot="progress"
            color="primary"
            indeterminate
          ></v-progress-linear>
          <template v-slot:body="{ items }">
            <tbody>
              <tr v-for="item in items" :key="item.id">
                <td>{{ item.business.display_name }}</td>
                <td>{{ item.date | date_format("MMMM YYYY") }}</td>
                <td class="text-right">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        color="primary"
                        outlined
                        icon
                        @click="selectedReport = item"
                      >
                        <v-icon small>fal fa-pen</v-icon>
                      </v-btn>
                    </template>
                    <span>Redigér</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        color="primary"
                        outlined
                        icon
                        :href="item.url"
                        target="_blank"
                      >
                        <v-icon small>fal fa-eye</v-icon>
                      </v-btn>
                    </template>
                    <span>Se rapport</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>

          <div class="text-center" slot="no-data">
            Ingen resultater :(
          </div>
        </v-data-table>
      </v-card-text>
    </v-card>
    <edit-managed-report-dialog
      v-model="selectedReport"
      @updated="
        getReports();
        selectedReport = null;
      "
    ></edit-managed-report-dialog>
  </div>
</template>

<script>
import date_format from "@/helpers/filters/date_format";
import EditManagedReportDialog from "../dialogs/EditManagedReportDialog";
import { mapActions } from "vuex";

export default {
  components: { EditManagedReportDialog },
  filters: { date_format },
  data: () => ({
    reports: [],
    headers: [
      { text: "Virksomhed", sortable: false },
      { text: "Måned", sortable: false },
      { text: "", sortable: false }
    ],
    selectedReport: null,
    dialog: false
  }),
  methods: {
    ...mapActions("moderator", ["getManagedReports"]),
    getReports() {
      this.getManagedReports().then(response => {
        this.reports = response;
      });
    }
  },
  created() {
    this.getReports();
  }
};
</script>
